@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&display=swap");

@tailwind base;

@layer base {
  html {
    scroll-behavior: smooth;
  }
  body {
    font-family: "Inter", sans-serif;
  }
}

@tailwind components;
@tailwind utilities;

.sticky {
  @apply ud-fixed ud-z-[9999] ud-transition ud-bg-white ud-bg-opacity-80;
  backdrop-filter: blur(5px);
  box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.1);
}

.sticky .header-logo {
  @apply ud-py-3 lg:ud-py-2;
}

.sticky .menu-scroll.active {
  @apply ud-text-primary;
}

input#checkboxLabel:checked ~ .box span {
  @apply ud-opacity-100;
}
